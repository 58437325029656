.image-gallery {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(200px, 1fr));
  justify-content: center;
  padding: 4px;
}

.image-gallery > p  {
  flex-basis: 25%;
  width: 100%;
  padding: 10px;
  margin: 2px;
  transform: scale(1);
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.05);
  }
}

.image-gallery .img-gallery {
  width: 100%;
  height: 240px;
  object-fit: cover;
}

.image-gallery .img-gallery {
  width: 100%;
  height: 240px;
  object-fit: cover;
}

div.language-download > pre {
  padding: 0.7rem;
}

div.language-changelog > pre {
  padding: 0.5rem 1.5rem;
}
